// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-casestudy-layout-js": () => import("./../src/components/casestudy-layout.js" /* webpackChunkName: "component---src-components-casestudy-layout-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-black-friday-js": () => import("./../src/pages/black-friday.js" /* webpackChunkName: "component---src-pages-black-friday-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-case-studies-js": () => import("./../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-expertise-apps-js": () => import("./../src/pages/expertise/apps.js" /* webpackChunkName: "component---src-pages-expertise-apps-js" */),
  "component---src-pages-expertise-index-js": () => import("./../src/pages/expertise/index.js" /* webpackChunkName: "component---src-pages-expertise-index-js" */),
  "component---src-pages-expertise-tech-js": () => import("./../src/pages/expertise/tech.js" /* webpackChunkName: "component---src-pages-expertise-tech-js" */),
  "component---src-pages-expertise-websites-js": () => import("./../src/pages/expertise/websites.js" /* webpackChunkName: "component---src-pages-expertise-websites-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

