/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
require ('./src/styles/global.scss')
require ("animate.css/animate.min.css")
require("./src/styles/tailwind.css") 
// in gastby-browser.js
exports.shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition,
  }) => {
    const { pathname } = location
    // list of routes for the scroll-to-top-hook
    const scrollToTopRoutes = [`/expertise/tech`, `/expertise/websites`, `/expertise/apps`, `/case-studies`, `/about`, `/expertise`, `/blog`, `/`, `/contact`, `/black-friday`]
    // if the new route is part of the list above, scroll to top (0, 0)
    if (scrollToTopRoutes.indexOf(pathname) !== -1) {
      window.scrollTo({top:0, left: 0, behavior: "smooth"})
    }
  
    return false
  }